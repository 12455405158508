<template>
    <div class="loginLog">
        <jy-query :model="formInline" ref="queryForm">
            <jy-query-item label="登录应用" prop="appId">
                <el-select v-model="formInline.appId" placeholder="请选择">
                    <el-option v-for="item in appOptions" :key="item.appId" :label="item.cName" :value="item.appId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="登录账号" prop="loginUser">
                <el-input v-model="formInline.loginUser" placeholder="输入账号"></el-input>
            </jy-query-item>
            <jy-query-item label="用户姓名" prop="loginName">
                <el-input v-model="formInline.loginName" placeholder="输入姓名"></el-input>
            </jy-query-item>
            <jy-query-item label="IP地址" prop="loginIp">
                <el-input v-model="formInline.loginIp" placeholder="输入IP地址"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <el-cascader ref="cascaderorg" :options="orgOptions" v-model="formInline.orgId" :props="orgProps" clearable></el-cascader>
            </jy-query-item>
            <jy-query-item label="登录时间" prop="timeDate" span="1.5">
                <el-date-picker v-model="formInline.timeDate" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('queryForm')">重置</el-button>
            </template>
        </jy-query>
        <jy-table :data="lists">
            <jy-table-column type="index" width="60" label="序号"></jy-table-column>
            <jy-table-column label="登录账号" prop="loginUser" min-width="100"></jy-table-column>
            <jy-table-column label="用户姓名" prop="loginName" min-width="100"></jy-table-column>
            <jy-table-column label="所属机构" prop="orgName" min-width="150"></jy-table-column>
            <jy-table-column label="登录应用" prop="appName" min-width="100"></jy-table-column>
            <jy-table-column label="IP地址" min-width="280">
                <template slot-scope="scope">
                    {{scope.row |changeIP}}
                </template>
            </jy-table-column>
            <jy-table-column label="浏览器" prop="browser" min-width="100"></jy-table-column>
            <jy-table-column label="异常现象" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.exceptionDetail }}
                </template>
            </jy-table-column>
            <jy-table-column label="登录时间" prop="loginTime" min-width="180"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            formInline: {
                appId: '',
                loginUser: '',
                loginName: '',
                loginIp: '',
                orgId: [],
                timeDate: [],
            },
            lists: [{}],
            orgProps: {
                checkStrictly: true,
                value: 'id',
                label: 'text',
            },
            appOptions: [],
            orgOptions: [],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
        }
    },
    created() {},
    activated() {
        this.getApplist()
    },
    filters: {
        change(val) {
            if (val == '0') {
                return '正常'
            } else {
                return '异常'
            }
        },
        changeIP(val) {
            if (val.loginProvince) {
                return val.loginIp + ' / ' + val.loginProvince
            } else {
                return val.loginIp
            }
        },
    },
    watch: {
        'formInline.appId'(val) {
            if (val) {
                this.formInline.orgId = []
                this.getOrgOption()
            } else {
                this.formInline.orgId = []
            }
        },
    },
    components: {},
    methods: {
        getOrgOption() {
            let url = '/sys/userApp/queryOrgDistribution'
            let option = {
                appId: this.formInline.appId,
            }
            this.$http.post(url, option).then(res => {
                console.log(res)
                this.orgOptions = res.detail
            })
        },
        getApplist() {
            let url = '/log/queryPcApp'
            this.$http.post(url).then(res => {
                this.appOptions = res.detail
                this.formInline.appId = res.detail[0].appId
                this.getlist()
            })
        },
        getlist() {
            let url = '/log/queryAbnormalLog'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            if (option.timeDate && option.timeDate.length) {
                option.startDate = option.timeDate[0]
                option.endDate = option.timeDate[1]
            } else {
                option.startDate = ''
                option.endDate = ''
            }
            option.orgId = option.orgId[option.orgId.length - 1]
            this.$http.post(url, option).then(res => {
                this.lists = res.detail.list
                this.total = res.detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.formInline.appId = this.appOptions[0].appId
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
    },
}
</script>
<style lang="scss" scoped="loginLog">
.loginLog {
}
</style>


